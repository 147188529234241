<template>
  <v-container>
    <div class="pa-2">
      <h1 class="primary--text">Référentiel</h1>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Catégorie
            </th>
            <th class="text-left">
              Code
            </th>
            <th class="text-left">
              Description
            </th>
            <th class="text-left">
              Unité
            </th>
            <th class="text-left">
              Prix unitaire
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          <tr class="primary lighten-2">
            <th><v-select v-model="filter_category" item-text="name" item-value="id" :items="filter_categories" label="Filtrer par catégorie"></v-select></th>
            <th></th>
            <th><v-text-field v-model="filter_name" label="Filtrer par nom"></v-text-field></th>
            <th colspan="3"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="price in filtered_referentiel"
              :key="price.id"
          >
            <td><span v-if="price.category !== null">{{ price.category.name }}</span></td>
            <td>{{ price.code }}</td>
            <td>{{ price.description }}</td>
            <td>{{ price.unit }}</td>
            <td>{{ price.price }}</td>

            <td>
              <EditReferentiel :price="price" :categories="categories"/>
              <v-btn color="red" icon @click="deleteReferentiel(price)" class="white--text"><v-icon>mdi-trash-can</v-icon></v-btn>
            </td>
          </tr>
          <tr>
            <td>
              <v-select v-model="referentielModel.category" :items="categories" item-value="id" item-text="name" label="Catégorie"></v-select>
            </td>
            <td>
              <v-text-field v-model="referentielModel.code"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="referentielModel.description"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="referentielModel.unit"></v-text-field>
            </td>
            <td>
              <v-text-field v-model="referentielModel.price"></v-text-field>
            </td>
            <td>
              <v-btn @click="createReferentiel" color="primary">Créer</v-btn>
            </td>

          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row class="mt-6">
        <v-col cols="6">
          <h1 class="primary--text mb-4">Catégories</h1>
          <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Nom de la catégorie
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="2">
              <v-checkbox v-model="showArchived" label="Montrer les catégories archivées"></v-checkbox>
            </td>
          </tr>
          <tr
              v-for="category in categories"
              :key="category.id"
          >
            <td>{{ category.name }} <span v-if="category.archived"><v-icon>mdi-archive</v-icon></span></td>
            <td>
              <EditCategory @reload="reloadCategories" :category="category"/>
              <v-btn color="red" icon @click="deleteCategory(category)" class="white--text"><v-icon>mdi-trash-can</v-icon></v-btn>
            </td>
          </tr>
          <tr>
            <td>
              <v-row align-content="center">
                <v-col cols="11">
                  <v-text-field label="Catégorie" v-model="category"></v-text-field>
                </v-col>
                <v-col cols="1" class="mt-5">
                  <v-btn icon @click="createCategory" color="primary">
                    <v-icon large>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import EditReferentiel from "@/views/EditReferentiel.vue";
import EditCategory from "@/views/EditCategory.vue";

export default {
  name: "Referentiel",
  components: {EditCategory, EditReferentiel},
  async mounted() {
    if(this.$store.getters["referentiel/loading_status"] !== "LOADED") {
      await this.$store.dispatch("referentiel/fetchReferentiel");
    }
    if(this.$store.getters["category/loading_status"] !== "LOADED") {
      await this.$store.dispatch("category/fetchCategory");
    }
  },
  data() {
    return {
      category: null,
      showArchived: false,
      referentielModel: {
        category: null,
        code: "",
        description: "",
        unit: "",
        price: null,
      },
      filter_category: null,
      filter_name: "",
    }
  },
  computed: {
    ...mapGetters({
      referentiel: "referentiel/referentiel",

    }),
    categories() {
      return this.$store.getters["category/categories"].filter(c => this.showArchived ? true : c.archived === false);
    },
    filter_categories() {
      return [{id: null, name: "Toutes les catégories"},...this.categories]
    },
    filtered_referentiel() {
      return this.referentiel
          .filter((referentielItem) => this.filter_name !== "" ? referentielItem.description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.filter_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) : true)
          .filter((referentielItem) => this.filter_category !== null ? referentielItem.category.id === this.filter_category : true)
          .sort((a, b) => a.category.name.localeCompare(b.category.name));
    }
  },
  methods: {
    async createCategory() {
      await this.$store.dispatch("category/postCategory", {name: this.category});
      this.category = null;
      this.$store.dispatch("annonce/annonceSuccess", "Catégorie créé.")
    },
    async createReferentiel() {
      let result = await this.$store.dispatch("referentiel/postReferentiel", this.referentielModel);
      if (result === null) {
        await this.$store.dispatch("annonce/annonceSuccess", "Prix créé.");
      }
    },
    async deleteReferentiel(price) {
      let result = await this.$store.dispatch("referentiel/deleteReferentiel", price);
      if(result === "success") {
        await this.$store.dispatch("annonce/annonceSuccess", "Prix supprimé.");
      }
    },
    async deleteCategory(category) {
      let result = await this.$store.dispatch("category/deleteCategory", category);
      if(result === "success") {
        return this.$store.dispatch("annonce/annonceSuccess", "Catégorie supprimé");
      }
      await this.$store.dispatch("category/fetchCategory");
    },
    async reloadPrices() {
      await this.$store.dispatch("referentiel/fetchReferentiel");
    },
    async reloadCategories() {
      await this.$store.dispatch("category/fetchCategory");
    },
  }

}
</script>
